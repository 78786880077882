import { isBrowser } from "./browser";
import { NEED_DEBUG_INFO_PARAM } from "./devHelpers";
import { pushDebugInfo } from "./pageState";

// Local storage
export function getFromStorage(key: string) {
  if (!isBrowser()) return null;
  const value = window.localStorage.getItem(key);

  const parsedValue = parseValue(value);
  return parsedValue;
}
export function writeToStorage(key: string, value: string = "true") {
  if (!isBrowser()) return null;
  _needDebugInfo() && sendDebugInfo(key, value, { isModified: true });
  return window.localStorage.setItem(key, value);
}
export function removeFromStorage(key: string) {
  if (!isBrowser()) return null;
  _needDebugInfo() && sendDebugInfo(key, "REMOVED", { isModified: true });
  return window.localStorage.removeItem(key);
}

// Session storage
export function getFromSession(key: string) {
  if (!isBrowser()) return null;
  const value = window.sessionStorage.getItem(key);

  const parsedValue = parseValue(value);
  return parsedValue;
}
export function writeToSession(key: string, value: string = "true") {
  if (!isBrowser()) return null;
  _needDebugInfo() && sendDebugInfo(key, value, { isSession: true, isModified: true });
  return window.sessionStorage.setItem(key, value);
}
export function removeFromSession(key: string) {
  if (!isBrowser()) return null;
  _needDebugInfo() && sendDebugInfo(key, "REMOVED", { isSession: true, isModified: true });
  return window.sessionStorage.removeItem(key);
}

function parseValue(value: string = "null"): boolean | string {
  const predefinedValuesDict = {
      true: true,
      false: false,
      null: false
    },
    predefinedValuesList = Object.keys(predefinedValuesDict);

  if (predefinedValuesList.includes(value)) {
    return predefinedValuesDict[value];
  } else {
    return value;
  }
}

function sendDebugInfo(key: string, value: string, options: { isSession?: boolean; isModified?: boolean } = {}) {
  const { isModified, isSession } = options;
  pushDebugInfo({
    title: `${isSession ? "Session storage" : "Storage"} ${isModified ? "modified" : "viewed"}`,
    message: `
      key: ${key},
      value: ${value}
    `
  });
}

function _needDebugInfo() {
  // calling NEED_DBEUG_INFO_PARAM directly because of recursion
  return window.sessionStorage.getItem(NEED_DEBUG_INFO_PARAM);
}

export const CHANGE_THEME_EVENT = "ThemeChanged";
export const PAGE_STATE_EVENT = "PageState";

export const AUTH_STATE_CHANGE_EVENT = "AuthStateChange";

// local storage keys
export const storageTokenKey = "token";
export const storageRefererKey = "referer";

// contacts
export const supportEmail = "support@travelbuddy.io";

// routes
export const Routes = {
  Home: "/",
  About: "/about",
  Contacts: "/contacts",
  Help: "/help",

  Terms: "/terms",
  Privacy: "/privacy",
  Cookie: "/cookie",

  Login: "/login",
  Registration: "/registration",

  Reset: "/reset",
  ResetConfirm: "/reset/confirm",

  Claims: "/claims",
  Claim: "/claims/[id]",
  ClaimBuddies: "/claims/[id]/buddies",
  ClaimChat: "/claims/[id]/chats/[chatId]",

  Profile: "/profile",
  ProfileById: "/profile/[id]",
  ProfileEdit: "/profile/edit",
  ProfilePhoto: "/profile/photo",
  ProfileBuddy: "/profile/buddy",
  ProfileClaims: "/profile/claims",

  404: "/404",
  500: "/500"
};

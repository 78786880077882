import axios from "axios";
import { saveUserSession } from "../helpers/userSession";

interface ObtainTokenResponse {
  access: string;
  refresh: string;
}
/** Obtain a token pair by email-password */
export async function tokenObtainLocal(email: string, password: string) {
  const res = await axios.post<ObtainTokenResponse>("/auth/token/obtain/local/", {
    email: email,
    password: password
  });
  const { access, refresh } = res.data;
  // save current user
  saveUserSession(access, refresh);

  return { access, refresh };
}

interface RefreshTokenResponse {
  access: string;
  refresh: string;
}
export async function refreshToken(token: string) {
  const res = await axios.post<RefreshTokenResponse>("/auth/token/refresh/", {
    refresh: token
  });
  const { access, refresh } = res.data;
  return { token: access, refresh: refresh };
}

interface CreateUserResponse {
  id: number;
  created_at: string;
  email: string;
  is_email_verified: boolean;
}
export async function createUser(_email: string, password: string, referer: string = "") {
  const res = await axios.post<CreateUserResponse>("/auth/user/", {
    email: _email,
    password: password,
    referer: referer
  });
  const { id, email } = res.data;
  return { id, email };
}

export async function resetPassword(email: string) {
  const res = await axios.post("/auth/password/reset/", {
    email: email
  });

  return true;
}

export async function resetPasswordConfirm(token: string, uidb64: string, newPassword: string) {
  const res = await axios.post("/auth/password/reset/confirm/", {
    token,
    uidb64,
    new_password: newPassword
  });

  return true;
}

export async function verifyEmail(email: string) {
  const res = await axios.post("/auth/email/verify/", {
    email: email
  });

  return true;
}

export async function verifyEmailConfirm(token: string, uidb64: string) {
  const res = await axios.post("/auth/email/verify/confirm/", {
    token,
    uidb64
  });

  return true;
}

interface ObtainOAuthTokenResponse {
  token: string;
  refresh: string;
}
export async function handleOAuth(code: string, provider: string) {
  const res = await axios.post<ObtainOAuthTokenResponse>("/auth/token/obtain/social/", {
    provider: provider,
    code: code
  });

  const { token, refresh } = res.data;
  // save current user
  saveUserSession(token, refresh);

  return res;
}

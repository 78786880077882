import { getFromSession } from "./storage";

export const NEED_DEBUG_INFO_PARAM = "NEED_DEBUG_INFO";

export function needDebugInfo() {
  return getFromSession(NEED_DEBUG_INFO_PARAM);
}

export function delay(delay: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, delay);
  });
}

export const isDevEnvironment = process.env.NODE_ENV === "development";
export const showDebugStaff =
  isDevEnvironment &&
  (process.browser ? process.env.NEXT_PUBLIC_FAKE_PRODUCTION_ENV : process.env.FAKE_PRODUCTION_ENV) !== "True";

// @see https://icons.getbootstrap.com
import React, { ReactElement } from "react";

interface Props {
  name: string;
  size?: number;
}

function BSIcon({ name, size = 16 }: Props): ReactElement {
  return <i className={`bi-${name}`} style={{ fontSize: size }} />;
}

export default BSIcon;

import { PAGE_STATE_EVENT } from "../constants";
import { DeviceTypes, getDeviceType, isBrowser } from "./browser";

export enum PageStates {
  success,
  error,
  warning,
  info
}

export interface PageStateInterface {
  type: PageStates;
  title: string;
  message: string;
  delay?: number;
  code?: string | number;
}

interface PushPageProps {
  title: string;
  message: string;
  code?: string | number;
}

const deviceType = getDeviceType();
const defaultDelay = deviceType === DeviceTypes.mobile ? 1500 : 3000;

export function pushPageError(props: PushPageProps) {
  const pageStateEvent = new CustomEvent<PageStateInterface>(PAGE_STATE_EVENT, {
    detail: { type: PageStates.error, delay: defaultDelay, ...props }
  });
  document.dispatchEvent(pageStateEvent);
}

export function pushPageNotSupported() {
  const pageStateEvent = new CustomEvent<PageStateInterface>(PAGE_STATE_EVENT, {
    detail: {
      type: PageStates.error,
      title: "Method not supported",
      message: "This message means that action you triggered is currently not implemented",
      delay: defaultDelay
    }
  });
  document.dispatchEvent(pageStateEvent);
}

export function pushDebugInfo(props: PushPageProps) {
  const pageStateEvent = new CustomEvent<PageStateInterface>(PAGE_STATE_EVENT, {
    detail: { type: PageStates.info, ...props }
  });
  document.dispatchEvent(pageStateEvent);
}

export function pushLog(message: any) {
  if (!isBrowser()) {
    return null; // logs only for browser
  }
  message = JSON.stringify(message);
  const pageStateEvent = new CustomEvent<PageStateInterface>(PAGE_STATE_EVENT, {
    detail: { type: PageStates.info, delay: 5000, title: "Debugger", message: message }
  });
  document.dispatchEvent(pageStateEvent);
}

export function pushPageInfo(props: PushPageProps) {
  const pageStateEvent = new CustomEvent<PageStateInterface>(PAGE_STATE_EVENT, {
    detail: { type: PageStates.info, delay: defaultDelay, ...props }
  });
  document.dispatchEvent(pageStateEvent);
}

export function pushPageWarning(props: PushPageProps) {
  const pageStateEvent = new CustomEvent<PageStateInterface>(PAGE_STATE_EVENT, {
    detail: { type: PageStates.warning, delay: defaultDelay, ...props }
  });
  document.dispatchEvent(pageStateEvent);
}

export function pushPageSuccess(props: PushPageProps) {
  const pageStateEvent = new CustomEvent<PageStateInterface>(PAGE_STATE_EVENT, {
    detail: { type: PageStates.success, delay: defaultDelay, ...props }
  });
  document.dispatchEvent(pageStateEvent);
}

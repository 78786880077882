import ym from "react-yandex-metrika";

import { isBrowser } from "./browser";
import { isDevEnvironment, needDebugInfo } from "./devHelpers";
import { pushDebugInfo } from "./pageState";

export const YM_METRIKA_ID = process.env.NEXT_PUBLIC_YAMETRIKA_ID;

export const GOAL_IDS = {
  Click_Landing_Learn_more: "Click_Landing_Learn_more",
  Click_Landing_Enjoy: " Click_Landing_Enjoy",
  Click_Landing_Create_Request: " Click_Landing_Create_Request",
  Scroll_Landing_Section_FAQ: " Scroll_Landing_Section_FAQ",

  Click_Notification_bell: "Click_Notification_bell",

  Submit_Create_Claim: "Submit_Create_Claim",
  Close_Create_Claim: "Close_Create_Claim",
  Click_Create_More_Claim: "Click_Create_More_Claim",

  Close_Cancel_Claim_Warning: "Close_Cancel_Claim_Warning",
  Click_Cancel_Claim: "Click_Cancel_Claim",
  Close_Close_Claim_Warning: "Close_Close_Claim_Warning",
  Click_Close_Claim: "Click_Close_Claim",

  Click_Request_Button_In_Claim: "Click_Request_Button_In_Claim",
  Click_Apply_For_Claim: "Click_Apply_For_Claim",
  Click_Candidate_Claim: "Click_Candidate_Claim",

  Click_Choose_Candidate: "Click_Choose_Candidate",
  Close_Payment_Form: "Close_Payment_Form",
  Submit_Payment_Success: "Submit_Payment_Success",
  Submit_Payment_Fail: "Submit_Payment_Fail",

  Submit_Become_Buddy: "Submit_Become_Buddy",
  Click_Abort_Buddy_Status: "Click_Abort_Buddy_Status",
  Close_Abort_Buddy_Status_Warning: "Close_Abort_Buddy_Status_Warning",
  Click_Logout_Button: "Click_Logout_Button",

  Click_Send_Again_Verification_Email_Warning: "Click_Send_Again_Verification_Email_Warning",
  Click_To_Profile_In_Waller_Warning: "Click_To_Profile_In_Waller_Warning",
  Close_Global_State: "Close_Global_State",

  Submit_Create_Account: "Submit_Create_Account",
  Click_Social_Login: "Click_Social_Login",
  Click_Skip_Photo_Step: "Click_Skip_Photo_Step",
  Click_Skip_Buddy_Step: "Click_Skip_Buddy_Step",
  View_Sussess_Registration_Screen: "View_Sussess_Registration_Screen",

  Submit_Edit_Profile: "Submit_Edit_Profile",
  Submit_Edit_Photo: "Submit_Edit_Photo",

  Submit_Reset_Password: "Submit_Reset_Password",

  Click_Mobile_Nav: "Click_Mobile_Nav",
  Click_Mobile_Burger: "Click_Mobile_Burger",

  Redirect_500_Page: "Redirect_500_Page",

  Click_Remove_Candidate: "Click_Remove_Candidate",
  Close_Remove_Candidate_Warning: "Close_Remove_Candidate_Warning",

  Click_Special_Sticker: "Click_Special_Sticker",
  Click_Special_Plane: "Click_Special_Plane"
};

export function hitPageView(url: string) {
  if (!isBrowser()) {
    return null;
  }

  if (isDevEnvironment) {
    needDebugInfo() &&
      pushDebugInfo({
        title: "Metrika sent",
        message: `visit page: ${url}`
      });
  } else {
    ym("hit", url);
  }
}

let goalsAchieved = [];
export function reachGoal(goalKey: keyof typeof GOAL_IDS, options: { [x: string]: any } & { isOnly?: boolean } = {}) {
  if (!isBrowser()) {
    return null;
  }
  const goal = GOAL_IDS[goalKey];

  const { isOnly, ...params } = options;
  const hasParams = Object.keys(params).length > 0;

  // no repeatable metrics with option isOnly
  if (goalsAchieved.includes(goal)) {
    if (isOnly) return null;
  } else {
    goalsAchieved.push(goal);
  }

  if (isDevEnvironment) {
    needDebugInfo() &&
      pushDebugInfo({
        title: "Metrika sent",
        message: `
      goal: ${goal}
      ${hasParams ? `params: ${JSON.stringify(params)}` : ""}
    `
      });
  } else {
    ym("reachGoal", goal, hasParams ? params : undefined);
  }
}

import axios from "axios";
import jwtDecode from "jwt-decode";
import { SetStateAction } from "react";
import { refreshToken } from "../apiHelpers/auth";
import { AUTH_STATE_CHANGE_EVENT, storageTokenKey } from "../constants";
import { UserRoles, UserStateInterface } from "../UserContext";
import { cache } from "swr";
import { getFromStorage, removeFromStorage, writeToStorage } from "./storage";
export interface AuthStateInterface {
  access: string;
  refresh: string;
}

interface TokenInner {
  token_type: "access" | "refresh";
  exp: number;
  jti: string;
  user_id: number;
  id: number;
  email: string;
  is_email_verified: boolean;
  name: string | null;
}
export function parseToken(token: string) {
  const decoded = jwtDecode<TokenInner>(token);
  return {
    id: decoded.id,
    email: decoded.email,
    isEmailVerified: decoded.is_email_verified // need to read from token for users without created profile
  };
}

export function saveUserSession(access: string, refresh: string) {
  document.dispatchEvent(
    new CustomEvent<AuthStateInterface>(AUTH_STATE_CHANGE_EVENT, {
      detail: {
        access: access,
        refresh: refresh
      }
    })
  );
}

export function clearUserSession() {
  document.dispatchEvent(new CustomEvent<AuthStateInterface>(AUTH_STATE_CHANGE_EVENT, null));
}

export async function refreshUserSession(refresh: string) {
  return refreshToken(refresh)
    .then(({ token, refresh: _refresh }) => {
      saveUserSession(token, _refresh || refresh);
      return token;
    })
    .catch((errObj) => {
      // token expire or error
      clearUserSession();
      return null;
    });
}

export async function refreshUserSessionFromStorage() {
  const token = getFromStorage(storageTokenKey);
  if (typeof token === "string") {
    refreshUserSession(token);
  } else {
    clearUserSession();
  }
}

let refreshUserSessionInterval: NodeJS.Timeout;
export function attachUserSessionListener(
  setUserContext: React.Dispatch<SetStateAction<UserStateInterface>>,
  initialRefreshToken?: string
) {
  const handleLogout = () => {
    removeFromStorage(storageTokenKey);
    // set context for swr queries
    setUserContext({
      userId: null,
      userRole: UserRoles.unauthorized,
      email: null,
      isEmailVerified: null,
      firebaseToken: null
    });

    axios.defaults.headers.common["Authorization"] = "";
    // clear swr requests cache
    cache.clear();

    refreshUserSessionInterval && clearInterval(refreshUserSessionInterval);
  };

  const handleLogin = (authState: AuthStateInterface) => {
    const accessToken = authState.access;
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    writeToStorage(storageTokenKey, authState.refresh);
    const tokenDecoded = parseToken(accessToken);
    // set id in context to make useCurrentUser work
    setUserContext({
      userId: tokenDecoded.id,
      userRole: UserRoles.authorized,
      email: tokenDecoded.email,
      isEmailVerified: tokenDecoded.isEmailVerified,
      firebaseToken: accessToken
    });
    refreshUserSessionInterval && clearInterval(refreshUserSessionInterval);
    refreshUserSessionInterval = setInterval(() => refreshUserSession(authState.refresh), refreshInterval);
  };

  const refreshInterval = 59 * 60000; // 59min
  const authChangeStateHandler = (e: CustomEvent<AuthStateInterface | null>) => {
    const authState = e.detail;
    if (!authState) {
      handleLogout();
    } else {
      handleLogin(authState);
    }
  };
  document.removeEventListener(AUTH_STATE_CHANGE_EVENT, authChangeStateHandler);
  document.addEventListener(AUTH_STATE_CHANGE_EVENT, authChangeStateHandler);

  if (initialRefreshToken) {
    refreshUserSession(initialRefreshToken);
  } else {
    handleLogout();
  }
}

import { NextRouter } from "next/router";

export function isBrowser() {
  return typeof window !== "undefined";
}

export enum DeviceTypes {
  mobile = "mobile",
  tablet = "tablet",
  desktop = "desktop"
}

export const getDeviceType = () => {
  if (!isBrowser()) return null;

  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return DeviceTypes.tablet;
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    return DeviceTypes.mobile;
  }
  return DeviceTypes.desktop;
};

export const isAndroid = () => {
  if (!isBrowser()) return null;

  const ua = navigator.userAgent;
  return /android(?!.*mobi)/i.test(ua);
};

export const parseQueryParam = (param: string | string[]): string => {
  return param && (typeof param === "string" ? param : param[0]);
};

export const getRefererParam = (router: NextRouter): string | null => {
  const { referer } = router.query;
  return referer ? parseQueryParam(referer) : null;
};

import { createContext } from "react";

export enum UserRoles {
  unauthorized = 0,
  authorized = 1,
  traveler = 2,
  buddy = 3
}

export interface UserStateInterface {
  userId: number | null;
  userRole: UserRoles | null;
  email: string | null;
  isEmailVerified: boolean | null;
  firebaseToken: string | null;
}

export const UserContext = createContext<UserStateInterface>({
  userId: null,
  userRole: null,
  email: null,
  isEmailVerified: true,
  firebaseToken: null
});
